import { Controller } from "@hotwired/stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {

  showEnquireNowForm(event) {
    event.preventDefault()
    let form = document.querySelector(`[data-partner-system-replicas-target="${event.target.dataset.partnerSystemReplicasTarget}"]`)
    utils.hide(event.target)
    utils.show(form)
  }

}
