import { Controller } from "@hotwired/stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  connect() {
    document.querySelectorAll("input, select").forEach(errorField => {
      errorField.addEventListener("focus", utils.scrollToElement)
    })
    window.setTimeout(e => this.revealErrors(), 200)
  }

  revealErrors() {
    var errorElement = document.querySelector(".error")

    if (errorElement) {
      utils.scrollToElement(errorElement)

      if (errorElement.previousSibling.type == "text") {
        errorElement.previousSibling.focus();
      }
    }
  }
}
