import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = []

  connect() {
    this.instantiateDialog()
  }

  instantiateDialog(event) {

    document.querySelectorAll("a[data-dialog-open]").forEach(element => {
      let openDialogLink = element

      if (openDialogLink) {
        let closeDialogLink = document.querySelector(`a[data-dialog-close="${openDialogLink.dataset.dialogOpen}"]`)
        let dialog = document.querySelector(`dialog#${openDialogLink.dataset.dialogOpen}`)

        openDialogLink.addEventListener("click", (event) => {
          event.preventDefault();
          dialog.showModal()
        })

        closeDialogLink.addEventListener("click", (event) => {
          event.preventDefault();
          dialog.close()
        })
      }
    })

  }

}
