import { Controller } from "@hotwired/stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {

  static targets = [
    "returnControlBtnSection",
    "returnControlBtn",
    "successSection",
    "provisoSection",
    "pendingSection",
    "skipBankStatementsBtnDiv",
    "loginInformation",
    "bankStatementRequestInstructions",
    "bankStatementRequestInformation",
    "bankStatementRequestInformationAfterSubmission"
  ]

  connect() {
    window.addEventListener("message", (event)=>{
      this.handleBankStatementUploadNotification(event)
    });
    this.showSkipBankStatementsBtn()
    this.doNotShowLinkToSkipBankStatements = true
  }

  handleBankStatementUploadNotification(e) {
    if (e.origin == this.data.get('proviso-base-url')) {
      const data = JSON.parse(e.data)
      if (data && data.status == 'success' && data.event == 'submission_complete') {
        this.showProvisoSuccess()
        this.doNotShowLinkToSkipBankStatements = false
        utils.hide(this.skipBankStatementsBtnDivTarget);
        utils.hide(this.bankStatementRequestInformationTarget);
      }
    }
  }

  showProvisoSuccess() {
    utils.hide(this.provisoSectionTarget)
    utils.show(this.pendingSectionTarget)
    utils.hide(this.bankStatementRequestInstructionsTarget);
    utils.show(this.bankStatementRequestInformationAfterSubmissionTarget);
    this.waitForOkStatus()
  }

  async waitForOkStatus() {
    await utils.sleep(5000);
    utils.hide(this.pendingSectionTarget)
    utils.hide(this.bankStatementRequestInformationAfterSubmissionTarget);
    utils.hide(this.loginInformationTarget);
    utils.show(this.successSectionTarget)
  }

  async waitingMessages() {
    this.returnControlBtnSectionTarget.remove()
    utils.show(this.pendingSectionTarget)
    utils.show(this.bankStatementRequestInformationAfterSubmissionTarget);

    await utils.sleep(500);
    this.successSectionTarget.innerHTML = "<h4 class='awaiting-message'>Processing</h4>"

    await utils.sleep(5000);
    this.successSectionTarget.innerHTML = "<h4 class='awaiting-message'>Awaiting information</h4>"

    await utils.sleep(5000);
    this.successSectionTarget.innerHTML = "<h4 class='awaiting-message'>Confirming income</h4>"

    await utils.sleep(5000);
    this.successSectionTarget.innerHTML = "<h4 class='awaiting-message'>Confirming expenses</h4>"

    await utils.sleep(5000);
    this.successSectionTarget.innerHTML = "<h4 class='awaiting-message'>Can take 10 to 60 seconds</h4>"
  }

  async showSkipBankStatementsBtn() {
    await utils.sleep(30000)
    if (this.doNotShowLinkToSkipBankStatements) {
      utils.show(this.skipBankStatementsBtnDivTarget);
    }
  };

}
