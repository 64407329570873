export function money(amount, precision) {
  precision = isNaN(precision) ? 2 : precision
  return formatNumber(amount, precision, '$')
}

export function number(amount, precision) {
  precision = isNaN(precision) ? 0 : precision
  return formatNumber(amount, precision, '')
}

export function percent(amount, precision) {
  precision = isNaN(precision) ? 2 : precision
  return number(amount * 100, precision) + "%"
}

export function formatNumber(amount, precision, prefix) {
  const decimals = isNaN(precision) ? 2 : precision
  const sign = (amount < 0) ? "-" : ""
  const int_part = String(parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimals)))
  const int_length = int_part.length
  const initial_commas = (int_length > 3) ? (int_length % 3) : 0;

  return sign + prefix + (initial_commas ? int_part.substr(0, initial_commas) + "," : "") + int_part.substr(initial_commas).replace(/(\d{3})(?=\d)/g, "$1" + ",") + (decimals ? "." + Math.abs(amount - int_part).toFixed(decimals).slice(2) : "");
}

export function moneyToAmount(money) {
  return parseInt(removeNonMoneyChars(money))
}

export function removeNonMoneyChars(str) {
  return str.
    replace(/\s/g,'').
    replace(/0\.([0-9])([0-9])k\b/ig,    '$1$20').replace(/0\.([0-9])k\b/ig,    '$100').
    replace(/\.([0-9])([0-9])k\b/ig,     '$1$20').replace(/\.([0-9])k\b/ig,     '$100').replace(/([0-9])k\b/ig,    '$1000').
    replace(/0\.([0-9])([0-9])m\b/ig, '$1$20000').replace(/0\.([0-9])m\b/ig, '$100000').
    replace(/\.([0-9])([0-9])m\b/ig,  '$1$20000').replace(/\.([0-9])m\b/ig,  '$100000').replace(/([0-9])m\b/ig, '$1000000').
    replace(/\..*./, '').
    replace(/[^[.0-9+*/-]/g,'')

}

export function percentToAmount(money) {
  return parseFloat(money.replace('%', '').replace(/,/g,''))
}