import { Controller } from "@hotwired/stimulus"
import * as formatter from 'src/js/format_helper'

export default class extends Controller {

  static targets = [
    "frequencyField",
    "amountField",
    "netAnnualAmount",
  ]

  connect() {
    this.annualAmount = 0
    this.calculateAmount()
  }

  updateAnnualAmount() {
    let selectedFrequencyElement = this.frequencyFieldTarget.querySelector("input:checked")
    let amountFieldValue = Number(formatter.removeNonMoneyChars(this.amountFieldTarget.value))

    if (amountFieldValue < 0) {
      return
    }

    if (selectedFrequencyElement == null) {
      return
    }

    let frequencyValue = selectedFrequencyElement.value

    if (/weekly/.test(frequencyValue)) {
      this.annualAmount = amountFieldValue * 52
    } else if(/fortnightly/.test(frequencyValue)) {
      this.annualAmount = amountFieldValue * 26
    } else if(/monthly/.test(frequencyValue)) {
      this.annualAmount = amountFieldValue * 12
    } else {
      this.annualAmount = amountFieldValue;
    }

    if (this.hasNetAnnualAmountTarget) {
      this.netAnnualAmountTarget.innerHTML = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD"}).format(this.annualAmount);
    }
  }

  calculateAmount() {
    let selectedFrequencyElement = this.frequencyFieldTarget.querySelector("input:checked")
    let amountFieldValue = Number(formatter.removeNonMoneyChars(this.amountFieldTarget.value))

    if (amountFieldValue <= 0) {
      return
    }

    if (selectedFrequencyElement == null) {
      return
    }

    let frequencyValue = selectedFrequencyElement.value

    if (/weekly/.test(frequencyValue)) {
      this.annualAmount = this.annualAmount || amountFieldValue * 52

      this.amountFieldTarget.value = formatter.money(Math.round(this.annualAmount / 52));
    } else if(/fortnightly/.test(frequencyValue)) {
      this.annualAmount = this.annualAmount || amountFieldValue * 26

      this.amountFieldTarget.value = formatter.money(Math.round(this.annualAmount / 26));
    } else if(/monthly/.test(frequencyValue)) {
      this.annualAmount = this.annualAmount || amountFieldValue * 12

      this.amountFieldTarget.value = formatter.money(Math.round(this.annualAmount / 12));
    } else {
      this.annualAmount = amountFieldValue;
    }

    if (this.hasNetAnnualAmountTarget) {
      this.netAnnualAmountTarget.innerHTML = formatter.money(this.annualAmount)
    }
  }

}
