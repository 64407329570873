import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "incomeAfterTaxLabel",
    "estimatedHouseHoldLivingExpensesLabel",
    "incomeFrequency",
  ]

  connect() {
    this.updateFieldLabel()
  }

  updateFieldLabel() {
    if (this.incomeFrequencyTarget.querySelector("input:checked") == null) {
      return
    }

    let frequencyValue = this.incomeFrequencyTarget.querySelector("input:checked").value

    if (/weekly/.test(frequencyValue)) {
      this.incomeAfterTaxLabelTarget.textContent = "Weekly income after tax";
    } else if(/fortnightly/.test(frequencyValue)) {
      this.incomeAfterTaxLabelTarget.textContent = "Fortnightly income after tax";
    } else if(/monthly/.test(frequencyValue)) {
      this.incomeAfterTaxLabelTarget.textContent = "Monthly income after tax";
    } else {
      this.incomeAfterTaxLabelTarget.textContent = "Income after tax";
    }
  }

}
