import { Controller } from "@hotwired/stimulus"
import { JourneyContainer,
         JourneyEvent,
         PreProcessor,
         TemplateType
       } from "/public/idscan-jcs"
import * as utils from "src/js/stimulus_helper"

const overrideTemplate = {
  'result': { type: TemplateType.String, provider: "" },
};

const csrfToken = document.head.querySelector("[name='csrf-token']").content

export default class extends Controller {

  static targets = [ "idCaptureFeedback", "badDocumentFeedback" ]

  connect() {
    navigator.mediaDevices.enumerateDevices()
    .then(function(devices) {
      if (!devices.some(device => device.kind == "videoinput")) {
        var idCaptureTarget = document.getElementById("idCapture")
        utils.hide(idCaptureTarget)

        this.idCaptureFeedbackTarget.innerHTML = inaccessibleCameraTemplate(this)
        utils.show(this.idCaptureFeedbackTarget)
      }
    })
    .catch(function(err){
      console.log(err.name + ": " + err.message);
    });

    if (sessionStorage.getItem("progressCode") == null) {
      sessionStorage.setItem("progressCode", "not_started")

      this.progressCode = "not_started"

      this.saveResponse(null, this.progressCode)
    }
    window.controller = this
  }

  captureId(event) {
    event.preventDefault()

    this.progressCode = "pending_licence_front"
    this.saveResponse(null, this.progressCode)

    this.showSkipIdScanForm()

    // Get access token
    const url = "/gbg/access_token"

    fetch(url, { method: 'POST' })
      .then(res => res.json())
      .then(data => { this.initJourneyContainer(data['access_token']) })
      .catch(error => utils.show(this.idCaptureFeedbackTarget))
  };

  initJourneyContainer(accessToken) {
    const gbgBaseUrl = new URL(this.data.get('gbg-base-url'))

    new JourneyContainer({
      backendUrl: gbgBaseUrl.origin,
      container: "#idCapture",
      token: accessToken,
      templates: overrideTemplate,
      onJourneyEvent: this.onJourneyEventResponse.bind(this),
      tripleScanGuidancePage: true,
      fileUploadOnCameraCaptureJourneys: true,
      assetsDirectory: "/gbg/assets",
      cameraOptions: {
        minimum: {
          horizontal: 400,
          vertical: 800,
        },
        ideal: {
          horizontal: 1080,
          vertical: 1920,
        }
      },
      smartCapture: {
        workerScriptUrl: "/ides-micro.js",
        asmScriptUrl: "/idesmicro_asm.js",
        timeout: 20000,
        autoStart: false,
      },
      manualCapture: {
        enabled: true,
        timeout: 10
      },
      hideLogo: true,
      hideAutoCaptureHints: false,
    }).initialize();
  };

  async showSkipIdScanForm() {
    if (!this.hasIdCaptureFeedbackTarget) {
      return true
    }
    await utils.sleep(30000)
    await utils.show(this.idCaptureFeedbackTarget)
  };

  async onJourneyEventResponse(event, meta, state) {
    switch (event) {
      case JourneyEvent.ERROR:
        this.idCaptureFeedbackTarget.innerHTML = "Please wait..."
        utils.show(this.idCaptureFeedbackTarget);
        this.idCaptureFeedbackTarget.innerHTML = inaccessibleCameraTemplate(this)
        break;
      case JourneyEvent.CAMERA_CAPABILITIES_UPDATED:
        if (meta.capabilities && Object.entries(meta.capabilities.cameras).length === 0) {
          this.idCaptureFeedbackTarget.innerHTML = inaccessibleCameraTemplate(this)
        }
        break;
      case JourneyEvent.JOURNEY_PROGRESS:
        if (state.journey.journeyId != undefined) {
          let idScanJourneyId = state.journey.journeyId;
          let requiredAction = state.journey.requiredAction;


          if (state.action == "IDENTITY:BACK" && requiredAction == "BACKSIDE") {
            this.saveResponse(idScanJourneyId, "BACKSIDE")
          }
          if (state.action == "PASSIVE_LIVENESS" && requiredAction == "BACKSIDE") {
            this.saveResponse(idScanJourneyId, "PASSIVE_LIVENESS")
          }
          else if (state.journey.currentResult == "Failed") {
            utils.show(this.badDocumentFeedbackTarget)
            this.idCaptureFeedbackTarget.innerHTML = ""

            let idCaptureElement = document.getElementById("idCapture")
            utils.hide(idCaptureElement)
            this.badDocumentFeedbackTarget.innerHTML = badScanDocumentTemplate(this);
          }
        }
        break;
      case JourneyEvent.DISPLAY_PROVIDER:
        document.getElementById("idCapture").scrollIntoView(true)
        break;
      case JourneyEvent.JOURNEY_END:
        let idScanJourneyId = state.journey.journeyId;
        let currentResult = state.journey.currentResult;
        let requiredAction = state.journey.requiredAction;

        if (requiredAction == "NONE" && currentResult == "Pass") {
          this.getStatusResponse()
          this.saveResponse(idScanJourneyId, currentResult)
        } else {
          this.saveResponse(idScanJourneyId, "Failed")
          let idCaptureElement = document.getElementById("idCapture")
          utils.hide(idCaptureElement)
          this.idCaptureFeedbackTarget.innerHTML = badScanDocumentTemplate(this);
        }
     };
  };

  saveResponse(idScanJourneyId, requiredAction) {
    var progressCode = "";

    if (requiredAction == "not_started") { progressCode = "not_started" }
    else if (requiredAction == "pending_licence_front") { progressCode = "pending_licence_front" }
    else if (requiredAction == "BACKSIDE") { progressCode = "pending_licence_back" }
    else if (requiredAction == "PASSIVE_LIVENESS") { progressCode = "pending_selfie" }
    else if (requiredAction == "Pass") { progressCode = "complete" }
    else if (requiredAction == "Failed") { progressCode = "failed" }

    if (progressCode != "") {
      fetch(this.data.get("update-customer-path"), {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({ id_scan_journey_id: idScanJourneyId,
                               id_scan_journey_progress_code: progressCode,
                               dealership_id: this.data.get("dealership-id"),
                               dealer_company_id: this.data.get("dealer-company-id")}),
      })
        .then(response => response.json())
        .then(data => { console.info(data) })
        .catch(error => console.error(error))
    }

  };

  getStatusResponse() {
    fetch(this.data.get("other-device-status-path"), {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    })
      .then(response => response.json())
      .then(data => {
        if (!data.fieldsOutstanding.some(attr => attr == "id_scan") && data.fieldsOutstanding.some(attr => attr == "initiating_device")) {
          window.controller.idCaptureFeedbackTarget.innerHTML = `
            <p>Close on this device and continue on computer</p>

            <form method="post" action=${this.data.get("update-other-devices-path")}>
              <input type="hidden" name="_method" value="put">
              <input class="btn btn-primary" type="submit" value="Continue">
              <input type="hidden" name="purpose" value="clear_initiating_device">
              <input type="hidden" name="authenticity_token" value=${csrfToken}>
            </form>
          `
        } else {
          window.controller.idCaptureFeedbackTarget.innerHTML = `
            <a href=${data.location} class="btn btn-primary">
              Continue
            </a>
          `
        }
      })
      .catch(error => console.error(error))
  }

}

function inaccessibleCameraTemplate(controller) {
  return `
    <strong>Unable to access camera</strong>
    <p>Here are the options to continue with your application</p?

    <br\>
    <br\>

    <ol>
      <li>Your browser may not be compatible to do a liveness check. Try a different browser, Chrome, Safari (Mac), or Internet Explorer (Windows).</li>
      <li>If your device has a camera. Try <a href="javascript:history.go(0)">reloading</a> your browser and provide camera access after clicking "Enable camera" button.</li>
      <li>Click the "Continue" button below to skip the liveness check.  You will need to do this later using your mobile device at the dealership.</li>
    </ul>

    <br>

    <form method="post" action=${controller.data.get("update-other-devices-path")}>
      <input type="hidden" name="_method" value="put">
      <input class="btn btn-primary" type="submit" value="Continue">
      <input type="hidden" name="purpose" value="id_scan_to_be_done_in_person">
      <input type="hidden" name="authenticity_token" value=${csrfToken}>
    </form>
  `
}

function gatewayTemplate() {
  return `
    <form data-jcs-element="gateway__upload" class="">
      <div data-jcs-element="provider__container" class="provider-container"></div>
    </form>
  `
}

function badScanDocumentTemplate(controller) {
  return `
    <strong>Problem detected while scanning</strong>
    <p>Here are the options to continue with your application</p?

    <br\>
    <br\>

    <ol>
      <li>You can try again. Try <a href="javascript:history.go(0)">reloading</a> your browser and provide camera access after clicking "Enable camera" button.</li>
      <li>Click the "Skip and do this later" button below to skip the liveness check. You will need to do this later using your mobile device at the dealership.</li>
    </ul>

    <br>

    <form method="post" action=${controller.data.get("update-other-devices-path")}>
      <input type="hidden" name="_method" value="put">
      <input class="btn btn-primary" type="submit" value="Skip and do this later">
      <input type="hidden" name="purpose" value="id_scan_to_be_done_in_person">
      <input type="hidden" name="authenticity_token" value=${csrfToken}>
    </form>
  `
}

