import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "loanTerm",
    "depositOrTradeIn",
    "repaymentFrequency",
    "paymentFrequencyAmount",
    "totalAmountPayable"
  ]

  connect() {
    this.handleCalculation()
  }

  calculate(e) {
    this.handleCalculation()
  }

  // MonthlyRepayment = ((Price + FinancedFees - Deposit) * (Rate / 1200)) / (1 - (1 + Rate / 1200)^(-Term)) + AKF
  // WeeklyDDOption = MonthlyRepayment * 12 / 52
  // FortnightlyDDOption = MonthlyRepayment * 12 / 26
  // Definitions:
  //  Inputs:
  //   Price: all-in vehicle price (including taxes and fees)
  //   Deposit: Cash or trade-in equity used to reduce amount financed
  //   FinancedFees: Total of the up-front fees charged to establish finance. Comprising the loan establishment fee, origination fee and PPSR fee
  //   Rate: The annual interest rate (note that this is not the same as the comparison rate, which is not needed in this calculation)
  //   Term: The loan term in months
  //   AKF: Monthly account keeping fee
  //  Outputs:
  //   MonthlyRepayment: The monthly amount due, and therefore if the customer chooses to pay monthly, the amount of the direct debit
  //   WeeklyDDOption: If the customer chooses to pay weekly, this is the amount of the weekly direct debit. Note that if the first month would result in only 4 direct debits, the first weekly direct debit will be more than the subsequent ones
  //   FortnightlyDDOption: If the customer chooses to pay fortnightly, this is the amount of the fortnightly direct debit. Note that if the first month would result in only 2 direct debits, the first fortnightly direct debit will be more than the subsequent ones

  handleCalculation() {
    const loanTerm = parseFloat(this.loanTermTarget.value)

    const depositOrTradeIn = parseFloat(this.depositOrTradeInTarget.value.replace(/[^\d.]/g, "")) || 0
    const rate = parseFloat(this.data.get('rate'))
    const establishmentFees = parseFloat(this.data.get('establishmentFees'))
    const monthlyFees = parseFloat(this.data.get('monthlyFees'))
    const price = parseFloat(this.data.get('price'))
    const naf = price + establishmentFees - depositOrTradeIn
    const monthlyRate = rate / 1200
    const monthlyRepayment = (naf * monthlyRate) / (1 - Math.pow((1 + monthlyRate), -loanTerm)) + monthlyFees
    const weeklyRepayment =  monthlyRepayment * 12 / 52
    const fortnightlyRepayment = monthlyRepayment  * 12 / 26

    let repaymentFrequencyValue = this.repaymentFrequencyTarget.querySelector("input:checked").value
    let value = ""

    if (repaymentFrequencyValue == "weekly" && weeklyRepayment > 0) {
      value = weeklyRepayment.toFixed(2)
    } else if(repaymentFrequencyValue == "fortnightly" && fortnightlyRepayment > 0) {
      value = fortnightlyRepayment.toFixed(2)
    } else if(repaymentFrequencyValue == "monthly" && monthlyRepayment > 0) {
      value = monthlyRepayment.toFixed(2)
    }

    value = new Intl.NumberFormat("en-AU", { style: "currency", currency: "AUD" }).format(value)
    this.paymentFrequencyAmountTarget.textContent = value

    this.updateTotalAmountPayable(monthlyRepayment, loanTerm)
  }

  updateTotalAmountPayable(monthlyRepayment, loanTerm) {
    let calcualtedTotalAmountPayable = (monthlyRepayment * loanTerm)
    calcualtedTotalAmountPayable = (calcualtedTotalAmountPayable < 0) ? "" : calcualtedTotalAmountPayable
    let totalAmountPayable = new Intl.NumberFormat("en-AU", { style: "currency", currency: "AUD" }).format(calcualtedTotalAmountPayable )

    this.totalAmountPayableTarget.innerHTML = Mustache.render(this.totalAmountPayableTarget.dataset.disclaimer, { totalAmountPayable: totalAmountPayable })
  }

}
