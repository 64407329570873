import { Controller } from "@hotwired/stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = ["source", "copyMessage"]

  async copy(event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.sourceTarget.textContent)
    document.execCommand("copy")
    var textContent = event.target.innerText
    event.target.innerText = "Copied!"
    await utils.sleep(2000)
    event.target.innerText = textContent
  }
}
