import { Controller } from "@hotwired/stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {

  static targets = [
    "incomeFrequency",
    "netRegularIncome",
    "jobDurationHelpText",
    "approximateStartDate",
    "addressInputOptionManual",
    "addressInputOptionAutoComplete",
    "addressEnteredManually"
  ]

  connect() {
    this.updateIncomeAfterTaxLabel()
    this.updateJobDurationInWords()
    this.handleAddresssInputSection()
  }

  updateIncomeAfterTaxLabel() {
    let incomeFrequencyElement = this.incomeFrequencyTarget.querySelector("input:checked")

    if(incomeFrequencyElement != null) {
      let incomeFrequencyValue = incomeFrequencyElement.value

      if (/weekly/.test(incomeFrequencyValue)) {
        this.netRegularIncomeTarget.innerHTML = "Weekly income after tax";
      } else if(/fortnightly/.test(incomeFrequencyValue)) {
        this.netRegularIncomeTarget.innerHTML = "Fortnightly income after tax";
      } else if(/monthly/.test(incomeFrequencyValue)) {
        this.netRegularIncomeTarget.innerHTML = "Monthly income after tax";
      } else {
        this.netRegularIncomeTarget.innerHTML = `Weekly income after tax`;
      }

    }
  }

  enableManualAddressInput(event) {
    event.preventDefault()
    utils.show(this.addressInputOptionManualTarget)
    utils.hide(this.addressInputOptionAutoCompleteTarget)
    utils.hide(event.target)
  }

  handleAddresssInputSection() {
    if (this.addressEnteredManuallyTarget.value == 'true'){
      utils.show(this.addressInputOptionManualTarget)
      utils.hide(this.addressInputOptionAutoCompleteTarget)
    } else {
      utils.show(this.addressInputOptionAutoCompleteTarget)
      utils.hide(this.addressInputOptionManualTarget)
    }
  }

  updateJobDurationInWords() {
    if (this.approximateStartDateTarget.value != "") {
      var startDateValue = moment(this.approximateStartDateTarget.value, "DD/MM/YYYY")
      var dateDifferenceInMonths = moment().diff(startDateValue, "months", true)
      const formattedCurrentDate = moment(new Date(), 'DD/MM/YYYY')
      const startDateCompare = moment(startDateValue, 'DD/MM/YYYY')

      if (dateDifferenceInMonths < 0) {
        return
      }

      if (dateDifferenceInMonths < 1) {
        this.jobDurationHelpTextTarget.innerText = "Less than a month"
      }
      if (dateDifferenceInMonths >= 1) {
        var dateDifferenceInYears = formattedCurrentDate.diff(startDateCompare, "years")
        startDateCompare.add(dateDifferenceInYears, "years")
        var months = formattedCurrentDate.diff(startDateCompare, "months")
        var monthText = this.pluralizeText(months, "month")
        if (dateDifferenceInYears < 1) {
          this.jobDurationHelpTextTarget.innerText = `About ${monthText}`
        } else {
          var yearText = this.pluralizeText(dateDifferenceInYears, "year")
          monthText = this.pluralizeText(months, "month")
          if (months >= 1 ){
            this.jobDurationHelpTextTarget.innerText = `About ${yearText}, ${monthText}`
          }else{
            this.jobDurationHelpTextTarget.innerText = `About ${yearText}`
          }

        }
      }
    } else {
      this.jobDurationHelpTextTarget.innerText = ""
    }
  }

  pluralizeText(number, text) {
    var roundedNumber = Math.round(number)

    if (roundedNumber > 1) {
      return `${roundedNumber} ${text}s`
    } else {
      return `${roundedNumber} ${text}`
    }
  }

}
