import { Controller } from "@hotwired/stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {

  static targets = [
    "mobileInputField",
    "emailInputField",
    "vehicleToTradeIn",
    "vehicleTradeInRegistrationInput",
    "vehicleTradeInStateInput"
  ]

  connect() {
    this.toggleVehicleTradeInOptions()
  }

  enableMobileInput(event) {
    event.preventDefault()
    this.mobileInputFieldTarget.disabled = false
    this.mobileInputFieldTarget.value = ""
    this.mobileInputFieldTarget.focus();
    utils.hide(event.target)
  }

  enableEmailInput(event) {
    event.preventDefault()
    this.emailInputFieldTarget.disabled = false
    this.emailInputFieldTarget.value = ""
    this.emailInputFieldTarget.focus();
    utils.hide(event.target)
  }

  toggleVehicleTradeInOptions() {
    if (this.hasVehicleToTradeInTarget) {
      let hasVehicleToTradeInChecked = this.vehicleToTradeInTarget.querySelector("input:checked");
      if (hasVehicleToTradeInChecked && (hasVehicleToTradeInChecked.value == "true")) {
        utils.show(this.vehicleTradeInRegistrationInputTarget)
        utils.show(this.vehicleTradeInStateInputTarget)
      }
      else {
        utils.hide(this.vehicleTradeInRegistrationInputTarget)
        utils.hide(this.vehicleTradeInStateInputTarget)
      }
    }
  }

}
