// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import jQuery from "jquery"
window.jQuery = jQuery
window.$ = jQuery

require("channels")
require("flatpickr/dist/themes/airbnb.css")

import Rails from '@rails/ujs';
Rails.start();

require("turbolinks").start()

import "controllers"
import "../src/stylesheets/stylesheet.scss"
import flatpickr from "flatpickr"
import "jquery-modal/jquery.modal.js"
import "jquery-modal/jquery.modal.min.css"

import Mustache from "mustache"
window.Mustache = Mustache

import moment from "moment"
window.moment = moment

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
