import { Controller } from "@hotwired/stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {

  static targets = [
    "rentOrMortgageAmountLabel",
    "residentialStatus",
    "rentOrMortgagePaymentFrequency",
    "rentOrMortgagePaymentFrequencyField",
    "rentOrMortgageAmountField",
    "anyInvestmentProperties",
    "mortgageAmountField",
    "remainingMortgageBalanceField",
    "ownedWithMortgageAdditionalField",
    "additionalMortgageAmountField",
    "ownerOrMortgageLabelField",
    "ownerOrMortgageLabel",
    "monthlyRatesFeesAndTaxExpensesField",
    "propertyValueField"
  ]

  connect() {
    this.updateRentMortgageAmountLabel()
    this.toggleMortgageAmountLabel()
  }

  updateRentMortgageAmountLabel() {
    if (this.hasRentOrMortgagePaymentFrequencyTarget){
      let rentOrMortgagePaymentFrequencyValue = this.rentOrMortgagePaymentFrequencyTarget.querySelector("input:checked").value
      let residentialStatusElement = this.residentialStatusTarget.querySelector("input:checked")

      if (residentialStatusElement != null) {
        let residentialStatusValue = residentialStatusElement.value
        switch(residentialStatusValue) {
          case "owned_with_mortgage":
            utils.hide(this.rentOrMortgagePaymentFrequencyFieldTarget)
            utils.show(this.rentOrMortgageAmountFieldTarget)
            utils.show(this.ownedWithMortgageAdditionalFieldTarget)
            utils.show(this.ownerOrMortgageLabelFieldTarget)
            utils.hide(this.propertyValueFieldTarget)
            this.toggleAdditionalMortgageAmount()
            utils.show(this.remainingMortgageBalanceFieldTarget)
            utils.show(this.monthlyRatesFeesAndTaxExpensesFieldTarget)
            this.rentOrMortgagePaymentFrequencyFieldTarget.querySelector("label").innerHTML = `Your ${rentOrMortgagePaymentFrequencyValue} mortgage payment frequency`;
            if (window.location.href.includes("carsales")){
              this.rentOrMortgageAmountFieldTarget.querySelector("input").placeholder = "Minimum monthly mortgage payment";
            }else{
              this.rentOrMortgageAmountFieldTarget.querySelector("input").placeholder = "";
            }
            this.rentOrMortgageAmountLabelTarget.innerHTML = "Minimum monthly mortgage payment";
            this.ownerOrMortgageLabelFieldTarget.querySelector("label").innerHTML = `Bank / Financier`;
            break;
          case "owned_outright":
            utils.show(this.propertyValueFieldTarget)
            utils.hide(this.rentOrMortgagePaymentFrequencyFieldTarget)
            utils.hide(this.rentOrMortgageAmountFieldTarget)
            utils.hide(this.ownedWithMortgageAdditionalFieldTarget)
            utils.hide(this.ownerOrMortgageLabelFieldTarget)
            utils.hide(this.remainingMortgageBalanceFieldTarget)
            utils.show(this.monthlyRatesFeesAndTaxExpensesFieldTarget)
            this.rentOrMortgageAmountLabelTarget.innerHTML = `Minimum ${rentOrMortgagePaymentFrequencyValue} payment for your rent or mortgage`;
            break;
          case "renting":
            utils.show(this.rentOrMortgagePaymentFrequencyFieldTarget)
            utils.show(this.rentOrMortgageAmountFieldTarget)
            utils.hide(this.ownedWithMortgageAdditionalFieldTarget)
            utils.show(this.ownerOrMortgageLabelFieldTarget)
            utils.hide(this.propertyValueFieldTarget)
            utils.hide(this.remainingMortgageBalanceFieldTarget)
            utils.hide(this.monthlyRatesFeesAndTaxExpensesFieldTarget)
            this.rentOrMortgagePaymentFrequencyFieldTarget.querySelector("label").innerHTML = `Your ${rentOrMortgagePaymentFrequencyValue} rent payment frequency`;
            this.rentOrMortgageAmountFieldTarget.querySelector("input").placeholder = `Minimum ${rentOrMortgagePaymentFrequencyValue} rent payment`;
            this.rentOrMortgageAmountLabelTarget.innerHTML = `Your ${rentOrMortgagePaymentFrequencyValue} rent`;
            this.ownerOrMortgageLabelFieldTarget.querySelector("label").innerHTML = `Landlord / Property manager`;
            break;
          case "living_with_relatives_or_boarding":
            utils.show(this.rentOrMortgagePaymentFrequencyFieldTarget)
            utils.show(this.rentOrMortgageAmountFieldTarget)
            utils.hide(this.ownedWithMortgageAdditionalFieldTarget)
            utils.show(this.ownerOrMortgageLabelFieldTarget)
            utils.hide(this.propertyValueFieldTarget)
            utils.hide(this.remainingMortgageBalanceFieldTarget)
            utils.hide(this.monthlyRatesFeesAndTaxExpensesFieldTarget)
            this.rentOrMortgagePaymentFrequencyFieldTarget.querySelector("label").innerHTML = `Your ${rentOrMortgagePaymentFrequencyValue} rent payment frequency`;
            this.rentOrMortgageAmountFieldTarget.querySelector("input").placeholder = `Minimum ${rentOrMortgagePaymentFrequencyValue} rent payment`;
            this.rentOrMortgageAmountLabelTarget.innerHTML = `Your ${rentOrMortgagePaymentFrequencyValue} rent`;
            this.ownerOrMortgageLabelFieldTarget.querySelector("label").innerHTML = `Who you are living with?`

            break;
          default:
            utils.show(this.rentOrMortgagePaymentFrequencyFieldTarget)
            utils.show(this.rentOrMortgageAmountFieldTarget)
            utils.hide(this.ownedWithMortgageAdditionalFieldTarget)
            utils.hide(this.ownerOrMortgageLabelFieldTarget)
            utils.hide(this.propertyValueFieldTarget)
            utils.hide(this.remainingMortgageBalanceFieldTarget)
            utils.hide(this.monthlyRatesFeesAndTaxExpensesFieldTarget)
            this.rentOrMortgageAmountLabelTarget.innerHTML = `Minimum weekly payment for your rent or mortgage`;
          }
      } else {
        this.rentOrMortgagePaymentFrequencyFieldTarget.querySelector("label").innerHTML = `Your ${rentOrMortgagePaymentFrequencyValue} rent/mortgage payment frequency`;
        this.rentOrMortgageAmountLabelTarget.innerHTML = `Minimum ${rentOrMortgagePaymentFrequencyValue} payment for your rent or mortgage`;
      }
    }

  }

  toggleMortgageAmountLabel(){
    if (this.hasAnyInvestmentPropertiesTarget){
      let investmentPropertyElement = this.anyInvestmentPropertiesTarget.querySelector("input:checked")
      if (investmentPropertyElement != null) {
        let investmentPropertyValue = investmentPropertyElement.value
        if (investmentPropertyValue == "true"){
          utils.show(this.mortgageAmountFieldTarget)
        }else{
          utils.hide(this.mortgageAmountFieldTarget)
        }
      }
    }
  }

  toggleAdditionalMortgageAmount(){
    if (this.hasOwnedWithMortgageAdditionalFieldTarget){
      let ownedWithMortgageAdditionalElement = this.ownedWithMortgageAdditionalFieldTarget.querySelector("input:checked")
      if (ownedWithMortgageAdditionalElement != null){
        let ownedWithMortgageAdditionalValue = ownedWithMortgageAdditionalElement.value
        if (ownedWithMortgageAdditionalValue == "true"){
          utils.show(this.additionalMortgageAmountFieldTarget)
        }else{
          utils.hide(this.additionalMortgageAmountFieldTarget)
        }
      }
    }

  }



}
