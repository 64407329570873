import { Controller } from "@hotwired/stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {

  static targets = [
    "carLoanOrOtherLoanSection",
    "hasReplacementCarLoan",
    "monthlyCarLoanRepaymentSection",
    "hasOtherLiabilities",
    "monthlyOtherLiabilityRepaymentSection",
  ]

  connect() {
    this.toggleMonthlyCarLoanRepaymentSection()
    this.toggleMonthlyOtherLiabilityRepaymentSection()
  }

  toggleMonthlyCarLoanRepaymentSection(){
    let hasReplacementCarLoanElement = this.hasReplacementCarLoanTarget.querySelector('input:checked')

    if (hasReplacementCarLoanElement != null){
      let hasReplacementCarLoanValue = hasReplacementCarLoanElement.value

      if (hasReplacementCarLoanValue == "true") {
        utils.showTargets(this.monthlyCarLoanRepaymentSectionTargets)
      } else {
        utils.hideTargets(this.monthlyCarLoanRepaymentSectionTargets)
      }
    }
  }

  toggleMonthlyOtherLiabilityRepaymentSection(){
    let hasOtherLiabilitiesElement = this.hasOtherLiabilitiesTarget.querySelector('input:checked')

    if (hasOtherLiabilitiesElement != null){
      let hasOtherLiabilitiesValue = hasOtherLiabilitiesElement.value

      if (hasOtherLiabilitiesValue == "true") {
        utils.showTargets(this.monthlyOtherLiabilityRepaymentSectionTargets)
      } else {
        utils.hideTargets(this.monthlyOtherLiabilityRepaymentSectionTargets)
      }
    }
  }

}
