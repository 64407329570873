import { Controller } from "@hotwired/stimulus"
import * as formatter from 'src/js/format_helper'

export default class extends Controller {
  static targets = [ "currency", "slider" ]

  connect () {
    this.updateSlider()
    this.updateCurrency()
  }

  updateCurrency() {
    if (this.hasSliderTarget){
      this.currencyTarget.value = formatter.money(this.sliderTarget.value, 0)
      this.getBackgroundSize(this.sliderTarget)
    }
  }

  updateSlider() {
    if (this.hasSliderTarget){
      this.sliderTarget.value = Math.floor(this.currencyTarget.value.replace(/[^0-9.]/g, ""))
      this.getBackgroundSize(this.sliderTarget)
    }
  }

  getBackgroundSize(input) {
    const min = input.min || 0;
    const max = input.max || 100;
    const value = input.value;

    const size = (value - min) / (max - min) * 100;

    input.style.setProperty("--background-size", `${size}%`);
  }

}
