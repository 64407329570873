// This makes a text input work as a calculator - if you enter a formula
// Into it, the formula is replaced with its result.

import { Controller } from "@hotwired/stimulus"
import * as formatter from 'src/js/format_helper'

export default class extends Controller {
  static targets = ["input"]

  calculateAndFormat(ev) {
    const element = ev.target
    const value = formatter.removeNonMoneyChars(element.value)

    let newvalue = eval(value)
    if(typeof newvalue == "number")
      element.value = formatter.money(newvalue, 0)
    else
      element.value = value
  }
}
