import { Controller } from "@hotwired/stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {

  static targets = [
    "saveProgressMessageContainer",
    "saveProgressSection",
    "saveProgressButton"
  ]

  save(event) {
    event.preventDefault()
    const url = this.saveProgressButtonTarget.getAttribute("data-url")
    const csrfToken = document.head.querySelector("[name='csrf-token']").content


    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRF-Token": csrfToken,
      }
    })
      .then((response) => {
        if (response.ok) {
          this.handleSuccess(response)
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
  }

  handleSuccess(response) {
    utils.hide(this.saveProgressSectionTarget)
    utils.show(this.saveProgressMessageContainerTarget)
  }
}
