import { Controller } from "@hotwired/stimulus"
import * as formatter from 'src/js/format_helper'

export default class extends Controller {
  reformatMoney(ev) {
    const element = ev.target
    element.value = formatter.money(formatter.moneyToAmount(element.value))
  }

  reformatDollar(ev) {
    const element = ev.target
    element.value = formatter.money(formatter.moneyToAmount(element.value), 0)
  }

  reformatNonBlankDollar(ev) {
    const element = ev.target
    if (element.value != '') {
      element.value = formatter.money(formatter.moneyToAmount(element.value), 0)
    }
  }

}
