export function sleep(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export function toggle(target, display) {
  target.classList.toggle("d-none", display)
}

export function toggle_if_exists(context, targetName, display) {
  const target = context.targets.find(targetName)
  if (target) target.classList.toggle("d-none", display)
}

export function show(target) {
  target.classList.remove("d-none")
}

export function showTargets(targets) {
  targets.forEach(target => {
    show(target)
  })
}

export function hide(target) {
  target.classList.add("d-none")
}

export function hideTargets(targets) {
  targets.forEach(target => {
    hide(target)
  })
}

export function toggle_parent(target, display) {
  display ? show_parent(target) : hide_parent(target)
}

// for labelled_select, labelled_textarea
export function show_parent(target) {
  target.parentElement.parentElement.classList.remove("d-none")
}

// for labelled_select, labelled_textarea
export function hide_parent(target) {
  target.parentElement.parentElement.classList.add("d-none")
}

export function disable(target) {
  target.disabled = true
}

export function enable(target) {
  target.disabled = false
}

export function makeEditable(target) {
  target.readOnly = false
}

export function notEditable(target) {
  target.readOnly = true
}

export function canEdit(editable) {
  if (editable == 'false') {
    const forms = document.getElementsByTagName('form')
    for (let form of forms) {
      disable_tags('input', form)
      disable_tags('select', form)
      disable_tags('textarea', form)
      disable_tags_by_class('btn-group', form)
    }
  }
}

export function readonly() {
  return document.querySelectorAll('form[data-readonly=true]').length == 1
}

export function disable_tags(tag_name, parent) {
  const tags = parent.getElementsByTagName(tag_name)
  for (let tag of tags) {
    let enabled = tag.dataset.enabled ||
      (["utf8", "_method", "authenticity_token"].indexOf(tag.name) >= 0) ||
      false
    if (enabled) {
      tag.disabled = null
      tag.readonly = null
    } else {
      tag.disabled = true
      tag.readonly = true
    }
  }
}

export function add_disable_class_to_tags(tag_name, parent) {
  const tags = parent.getElementsByTagName(tag_name)
  for (let tag of tags) {
    tag.className += ' disabled'
  }
}

export function disable_tags_by_class(class_name, parent) {
  const tags = parent.getElementsByClassName(class_name)
  for (let tag of tags) {
    tag.className += ' disabled'
  }
}

export function scrollToSection(id) {
  scrollToElement(document.getElementById(id))
}

export function scrollToEventTarget(ev) {
  scrollToElement(ev.target)
}

export function scrollToElement(element) {
  var top_y = -50
  var offsetLoopElement = element
  while (offsetLoopElement) {
    top_y = top_y + offsetLoopElement.offsetTop
    offsetLoopElement = offsetLoopElement.offsetParent
  }
  var bottom_y = top_y + element.offsetHeight

  var offset_top = 0
  var offset_bottom = 0
  document.querySelectorAll(".sticky-top").forEach(e => {offset_top += e.offsetHeight})
  document.querySelectorAll("#button-row").forEach(e => {offset_bottom += e.offsetHeight})
  const viewport_top = window.scrollY + offset_top
  const viewport_size = window.innerHeight - offset_top - offset_bottom
  const viewport_bottom = viewport_top + viewport_size

  const min_gap_to_viewport = 32
  const desired_gap_to_viewport = element.offsetHeight + min_gap_to_viewport
  if (top_y < (viewport_top + min_gap_to_viewport)) {
    window.scrollTo(0, top_y - offset_top - desired_gap_to_viewport)
  } else if ((viewport_bottom - min_gap_to_viewport) < bottom_y) {
    window.scrollTo(0, bottom_y + offset_bottom - window.innerHeight + desired_gap_to_viewport)
  }

}

export function findTargetFromTargetsByClass(targets, className, hasClass=true) {
  return targets.find(function(target) {
    const containsClass = target.classList.contains(className)
    return hasClass ? containsClass : !containsClass
  })
}

export function toggleDependentFields(targets, choice) {
  targets.forEach(target => {
    toggle(target, !(target.dataset.for.split(',').includes(choice)))
  })
}