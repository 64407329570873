import { Controller } from "@hotwired/stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {

  static targets = [
    "changeInFinancialPosition",
    "potentialFinancialChangesDetailsField",
  ]

  connect() {
    this.togglePotentialFinancialChangesDetails()
  }

  togglePotentialFinancialChangesDetails() {
    let changeInFinancialPositionElement = this.changeInFinancialPositionTarget.querySelector("input:checked")

    if (changeInFinancialPositionElement != null) {
      let changeInFinancialPositionValue  = changeInFinancialPositionElement.value

      if (changeInFinancialPositionValue == "true") {
        utils.show(this.potentialFinancialChangesDetailsFieldTarget)
      } else {
        utils.hide(this.potentialFinancialChangesDetailsFieldTarget)
      }
    }
  }

}
