import { Controller } from "@hotwired/stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {

  static targets = [
    "tooltipContentContainer",
  ]

  toggleContent() {
    if (this.tooltipContentContainerTarget.classList.contains("d-none")) {
      utils.show(this.tooltipContentContainerTarget)
    } else {
      utils.hide(this.tooltipContentContainerTarget)
    }
  }

}
