import { Controller } from "@hotwired/stimulus"
import * as utils from "src/js/stimulus_helper"
import * as format_helper from "src/js/format_helper"

export default class extends Controller {

  static targets = [
    "secondaryJobContainer",
    "addSecondJob",
    "planToAddAnotherRegularIncome",
    "secondaryIncomeTypeContainer",
    "secondaryIncomeNetRegularAmountContainer",
    "secondaryIncomeNetRegularLabel",
    "secondaryIncomeFrequencyContainer",
    "secondaryIncomeFrequencyField",
    "childSupportPayment",
    "childSupportIncomeFrequencyLabel",
    "childSupportIncomeFrequencyContainer",
    "governmentSupportPayment",
    "governmentSupportIncomeFrequencyLabel",
    "governmentSupportIncomeFrequencyContainer",
  ]

  connect() {
    this.updateIncomeAfterTaxLabel()
    this.toggleIncomeTypeFields()
    this.toggleChildSupportFields()
    this.toggleGovernmentBenefitSupportFields()
    this.toggleSecondaryJobFields()
  }

  updateIncomeAfterTaxLabel() {
    let selectedElement = this.secondaryIncomeFrequencyContainerTarget.querySelector("input:checked")

    if (selectedElement) {
      let value = selectedElement.value

      switch(value) {
        case "weekly":
          this.secondaryIncomeNetRegularLabelTarget.textContent = "Weekly income after tax";
          break;
        case "fortnightly":
          this.secondaryIncomeNetRegularLabelTarget.textContent = "Fortnightly income after tax";
          break;
        case "monthly":
          this.secondaryIncomeNetRegularLabelTarget.textContent = "Monthly income after tax";
          break;
        default:
          this.secondaryIncomeNetRegularLabelTarget.textContent = "Income after tax";
      }
    }
  }

  toggleIncomeTypeFields() {
    let planToAddAnotherRegularIncomeInputElement = this.planToAddAnotherRegularIncomeTarget.querySelector("input:checked")

    if (planToAddAnotherRegularIncomeInputElement) {
      let planToReduceMonthlyExpensesInputValue = planToAddAnotherRegularIncomeInputElement.value

      if (planToReduceMonthlyExpensesInputValue == "true"){
        utils.show(this.secondaryIncomeTypeContainerTarget)
        utils.show(this.secondaryIncomeFrequencyContainerTarget)
        utils.show(this.secondaryIncomeNetRegularAmountContainerTarget)
      } else {
        utils.hide(this.secondaryIncomeTypeContainerTarget)
        utils.hide(this.secondaryIncomeFrequencyContainerTarget)
        utils.hide(this.secondaryIncomeNetRegularAmountContainerTarget)
      }
    }
  }

  toggleSecondaryJobFields(){
    let secondaryJobElement = this.addSecondJobTarget.querySelector("input:checked")
    if (secondaryJobElement){
      let secondaryJobInputValue = secondaryJobElement.value
      if (secondaryJobInputValue == "true"){
        utils.show(this.secondaryJobContainerTarget)
      }else{
        utils.hide(this.secondaryJobContainerTarget)
      }
    }

  }

  toggleChildSupportFields(){
    let childSupportIncomeFrequencyValue = this.childSupportPaymentTarget.value
    if (childSupportIncomeFrequencyValue != ""){
      if(format_helper.moneyToAmount(childSupportIncomeFrequencyValue) > 0) {
        utils.show(this.childSupportIncomeFrequencyContainerTarget)
      }else{
        utils.hide(this.childSupportIncomeFrequencyContainerTarget)
      }
    }else{
      utils.hide(this.childSupportIncomeFrequencyContainerTarget)
    }

  }

  toggleGovernmentBenefitSupportFields(){
    let governmentSupportIncomeFrequencyValue = this.governmentSupportPaymentTarget.value
    if (governmentSupportIncomeFrequencyValue != ""){
      if  (format_helper.moneyToAmount(governmentSupportIncomeFrequencyValue) >0) {
        utils.show(this.governmentSupportIncomeFrequencyContainerTarget)
      }else{
        utils.hide(this.governmentSupportIncomeFrequencyContainerTarget)
      }
    }else{
      utils.hide(this.governmentSupportIncomeFrequencyContainerTarget)
    }
  }

}
