import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "query", "results", "addressUnit", "addressStreetType",
                     "addressStreetName", "addressStreetNumber", "addressState",
                     "addressPostcode", "addressLocality", "addressDpid", "canonicalId" ]

  disconnect() {
    if (this.hasQueryTarget) {
      this.reset()
    }
  }

  resetAutocomplete(event) {
    if (this.hasQueryTarget) {
      if ((event.target != this.queryTarget) || (event.target != this.resultsTarget)) {
        this.resultsTarget.innerHTML = ""
        return
      }
    }
  }

  fetchResults(event) {
    if (event.key == "Escape") {
      this.resultsTarget.innerHTML = ""
      return
    }

    if(this.query == "") {
      var data = { address_unit: "", address_street_number: "", address_street_name: "",
                   street_type_code: "", address_locality: "", address_state: "",
                   address_postcode: "", address_dpid: "", complete_address: "", canonical_id: "" }
      this.populateFieldsAndReset(data)
      return
    }

    if(this.query == this.previousQuery) {
      return
    }

    this.previousQuery = this.query

    const url = new URL(this.data.get("url"))
    url.searchParams.append("query", this.query)

    this.abortPreviousFetchRequest()

    this.abortController = new AbortController()
    if (this.queryTarget.value.length > 3) {
      fetch(url, { signal: this.abortController.signal })
        .then(response => response.text())
        .then(html => {
          this.resultsTarget.innerHTML = html
        })
        .catch(() => {})
    }
  }

  fetchRecord(event) {
    event.preventDefault()

    this.abortController = new AbortController()
    fetch(event.currentTarget.dataset.url, { signal: this.abortController.signal })
      .then(response => response.json())
      .then(data => this.populateFieldsAndReset(data))
      .catch(() => {})
  }

  populateFieldsAndReset(data) {
    this.addressUnitTarget.value = data.address_unit
    this.addressStreetNumberTarget.value = data.address_street_number
    this.addressStreetNameTarget.value = data.address_street_name
    this.addressStreetTypeTarget.value = data.street_type_code
    this.addressLocalityTarget.value = data.address_locality
    this.addressStateTarget.value = data.address_state
    this.addressPostcodeTarget.value = data.address_postcode
    this.addressDpidTarget.value = data.address_dpid
    this.canonicalIdTarget.value = data.canonical_id

    this.reset()
    this.queryTarget.value = data.complete_address
  }

  // private

  reset() {
    this.resultsTarget.innerHTML = ""
    this.queryTarget.value = ""
    this.previousQuery = null
  }

  abortPreviousFetchRequest() {
    if(this.abortController) {
      this.abortController.abort()
    }
  }

  get query() {
    return this.queryTarget.value
  }

}
