import { Controller } from "@hotwired/stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {

  static targets = [
    "planToReduceMonthlyExpensesInput",
    "reduceExpensesByAmountField",
  ]

  connect() {
    this.toggleReduceExpensesOptions()
  }

  toggleReduceExpensesOptions() {
    if(this.hasPlanToReduceMonthlyExpensesInputTarget) {

      let planToReduceMonthlyExpensesInputElement = this.planToReduceMonthlyExpensesInputTarget.querySelector("input:checked")

      if (planToReduceMonthlyExpensesInputElement) {

        let planToReduceMonthlyExpensesInputValue = planToReduceMonthlyExpensesInputElement.value

        if (planToReduceMonthlyExpensesInputValue == "true") {
          utils.show( this.reduceExpensesByAmountFieldTarget)
        }
        else {
          utils.hide(this.reduceExpensesByAmountFieldTarget)
        }

      }
    }
  }

}
