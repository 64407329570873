import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "dateField" ]

  connect() {
    this.instantiateFlatpickr()
  }

  instantiateFlatpickr(event) {
    if (this.hasDateFieldTarget) {
      flatpickr(this.dateFieldTarget, {
        dateFormat: "d/m/Y",
        allowInput: true,
        disableMobile: true,
        maxDate: this.dateFieldTarget.dataset.maxdate,
      });
    }
  }

}
