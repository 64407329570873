import { Controller } from "@hotwired/stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {

  static targets = [
    "visaExpiryField",
    "residencyStatus",
    "visaSubclassField",
    "visaGrantNumberField"
  ]

  toggleVisaExpiryField() {
    let selectedValue = this.residencyStatusTarget.querySelector("input:checked").value
    if (selectedValue == "other_nationality") {
      utils.show(this.visaExpiryFieldTarget)
      utils.show(this.visaSubclassFieldTarget)
      utils.show(this.visaGrantNumberFieldTarget)
    } else {
      utils.hide(this.visaExpiryFieldTarget)
      utils.hide(this.visaSubclassFieldTarget)
      utils.hide(this.visaGrantNumberFieldTarget)
    }
  }

}
